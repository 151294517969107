var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"app"},[_c('v-header'),_c('v-hero',{attrs:{"src":require('@/assets/images/hero-1@1x.jpg'),"srcset":("\n      " + (require('@/assets/images/hero-1@0.5x.jpg')) + " 368w,\n      " + (require('@/assets/images/hero-1@1x.jpg')) + " 734w,\n      " + (require('@/assets/images/hero-1@1.5x.jpg')) + " 1101w\n    "),"sizes":"\n      (max-width: 414px) 80vw,\n      (max-width: 768px) 60vw,\n      489px\n    ","caption":'Summer 21 collection\nby Anthony Vaccarello'}}),_c('v-cover',{attrs:{"src":"https://storage.googleapis.com/summer-21/videos/cover-1.mp4","poster":require('@/assets/images/cover-1.jpg'),"ratio":16 / 9}}),_c('v-figure',{attrs:{"src":require('@/assets/images/figure-1@1x.jpg'),"srcset":("\n      " + (require('@/assets/images/figure-1@0.5x.jpg')) + " 323w,\n      " + (require('@/assets/images/figure-1@1x.jpg')) + " 645w,\n      " + (require('@/assets/images/figure-1@1.5x.jpg')) + " 968w\n    "),"sizes":"(max-width: 414px) 80vw, 50vw","caption":'Photographed by\nJuergen Teller'}}),_c('v-slideshow',{staticClass:"app__slideshow",attrs:{"gutters":"","slides":[
      {
        src: require('@/assets/images/slideshow-1@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-1@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-1@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-1@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      },
      {
        src: require('@/assets/images/slideshow-2@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-2@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-2@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-2@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      }
    ]}}),_c('v-cover',{attrs:{"src":require('@/assets/images/cover-2@1x.jpg'),"srcset":("\n      " + (require('@/assets/images/cover-2@0.5x.jpg')) + " 720w,\n      " + (require('@/assets/images/cover-2@1x.jpg')) + " 1440w,\n      " + (require('@/assets/images/cover-2@1.5x.jpg')) + " 2160w\n    "),"sizes":"80vw","ratio":2 / 3}}),_c('v-slideshow',{staticClass:"app__slideshow",attrs:{"slides":[
      { caption: 'Le 5 à 7' },
      {
        src: 'https://storage.googleapis.com/summer-21/videos/diptych-3.mp4',
        poster: require('@/assets/images/slideshow-3.jpg')
      }
    ]}}),_c('v-slideshow',{staticClass:"app__slideshow",attrs:{"slides":[
      {
        src: require('@/assets/images/slideshow-1@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-1@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-1@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-1@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      },
      {
        src: require('@/assets/images/slideshow-4@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-4@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-4@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-4@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      }
    ]}}),_c('v-cover',{attrs:{"src":"https://storage.googleapis.com/summer-21/videos/cover-3.mp4","poster":require('@/assets/images/cover-1.jpg'),"ratio":16 / 9}}),_c('v-slideshow',{staticClass:"app__slideshow",attrs:{"slides":[
      {
        src: require('@/assets/images/slideshow-5@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-5@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-5@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-5@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      },
      {
        src: require('@/assets/images/slideshow-6@1x.jpg'),
        srcset: ("\n          " + (require('@/assets/images/slideshow-6@0.5x.jpg')) + " 323w,\n          " + (require('@/assets/images/slideshow-6@1x.jpg')) + " 645w,\n          " + (require('@/assets/images/slideshow-6@1.5x.jpg')) + " 968w\n        "),
        sizes: '(max-width: 414px) 80vw, 50vw'
      }
    ]}}),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }