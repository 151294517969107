<template>
    <div class="stripes">
        <h2>{{ title }}</h2>
        <p>{{ subTitle }}</p>
    </div>
</template>

<script>

export default {
  name: 'VStripes',
    props: {
        title: {
            type: String,
            default: false,
            required: true
        },
        subTitle: {
            type: String,
            default: false,
            required: true
        },
  },
}
</script>

<style scoped lang="sass">
.stripes 
    width: calc(100% - var(--guter)*2)
    margin: var(--gutter) auto
    margin-bottom: calc(var(--gutter)/2)
    border-top: 2px solid black
    border-bottom: 2px solid black
    padding: 5px 0
    

    h2, p
        text-align: center
        text-transform: uppercase
        font-size: 10px
        line-height: 1em

    h2
        font-weight: bold
        @media screen and (min-width: 768px)
            font-size: 16px

    p
        letter-spacing: -0.5px
        font-size: 8px
        @media screen and (min-width: 768px)
            font-size: 12px


</style>
