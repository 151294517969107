<template>
    <div class="title">
        <img
            class="title_logo"
            alt="Saint laurent"
            :src="require('../assets/images/logo.svg')"
          >
    </div>
</template>

<script>

export default {
  name: 'VTitle',
}
</script>

<style scoped lang="sass">
.title 
    box-sizing: border-box
    width: calc(100% - var(--guter)*2)
    margin: var(--gutter) auto
    margin-bottom: calc(var(--gutter)/2)

    img 
        width: 100%
</style>
