import { render, staticRenderFns } from "./VTitle.vue?vue&type=template&id=f8cc4b4a&scoped=true&"
import script from "./VTitle.vue?vue&type=script&lang=js&"
export * from "./VTitle.vue?vue&type=script&lang=js&"
import style0 from "./VTitle.vue?vue&type=style&index=0&id=f8cc4b4a&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8cc4b4a",
  null
  
)

export default component.exports